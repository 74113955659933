import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { HeaderLargeText, SubContentText, StyledPagination } from "../elements"
import { useStaticQuery } from "gatsby"
import styled from "styled-components"
import { StyledBlog } from "../layouts"

const StyledCategories = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 600px;
  margin: auto;
  width: fit-content;
  gap: 30px;
  p {
    cursor: pointer;
    &:hover {
      font-weight: bold;
    }
  }
  @media (max-width: 1000px) {
    overflow: auto;
    white-space: nowrap;
    padding-left: 15px;
    max-width: 100%;
    p {
      padding: 0 3rem 0 0;
    }
  }
`

const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
  max-width: 1120px;
  padding: 0 15px;
  margin: 40px auto;
  @media (max-width: 650px) {
    grid-template-columns: 1fr;
  }
  > div:nth-child(1),
  > div:nth-child(2),
  > div:nth-child(7),
  > div:nth-child(8) {
    grid-column: auto / span 2;
    @media (max-width: 650px) {
      grid-column: auto / span 1;
    }
  }
  > div {
    max-width: 100%;
  }
  h3 {
    text-align: start;
    padding: 0;
  }
`

const BlogParentTemplate = props => {
  const { title } = props.pageContext
  const [state, setState] = useState("")
  const [blogs, setBlogs] = useState([])
  const [page, setPage] = useState(1)

  useEffect(() => {
    if (state === "") {
      setBlogs(data.allStrapiBlogs.edges)
    } else {
      setBlogs(
        data.allStrapiBlogs.edges
          .filter(
            article =>
              article.node.category !== null &&
              article.node.category.name === state
          )
          .reverse()
      )
    }
  }, [state])

  const paginator = (items, current_page, per_page_items) => {
    let page = current_page || 1,
      per_page = per_page_items || 10,
      offset = (page - 1) * per_page,
      paginatedItems = items.slice(offset).slice(0, per_page_items),
      total_pages = Math.ceil(items.length / per_page)

    return {
      page: page,
      per_page: per_page,
      pre_page: page - 1 ? page - 1 : null,
      next_page: total_pages > page ? page + 1 : null,
      total: items.length,
      total_pages: total_pages,
      data: paginatedItems,
    }
  }

  const data = useStaticQuery(graphql`
    query {
      allStrapiBlogs {
        edges {
          node {
            ShortDescription
            Slug
            Title
            category {
              id
              name
            }
            Image {
              publicURL
            }
          }
        }
      }
      allStrapiCategories {
        edges {
          node {
            id
            name
            blogs {
              id
              ShortDescription
              Slug
              Title
              Image {
                publicURL
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Articles" />
      <HeaderLargeText>{title}</HeaderLargeText>
      <StyledCategories>
        {data.allStrapiCategories.edges.map(category => {
          if (category.node.blogs.length === 0) {
            return
          }
          return (
            <SubContentText
              key={category.node.id}
              onClick={() => setState(category.node.name)}
              bold={state === category.node.name ? true : false}
            >
              {category.node.name} ({category.node.blogs.length})
            </SubContentText>
          )
        })}
      </StyledCategories>
      <StyledContainer>
        {paginator(blogs.reverse(), page, 8).data.map((blog, i) => {
          const largeItems = [0, 1, 6, 7]
          if (largeItems.includes(i)) {
            return (
              <StyledBlog
                large
                image={blog.node.Image.publicURL}
                title={blog.node.Title}
                description={blog.node.ShortDescription}
                link={blog.node.Slug}
                key={blog.node.Title}
              />
            )
          }
          return (
            <StyledBlog
              image={blog.node.Image.publicURL}
              title={blog.node.Title}
              description={blog.node.ShortDescription}
              link={blog.node.Slug}
              key={blog.node.Title}
            />
          )
        })}
      </StyledContainer>
      {paginator(blogs.reverse(), page, 8).total_pages > 1 && (
        <StyledPagination
          currentPage={page}
          pages={paginator(blogs, page, 8).total_pages}
          changePage={setPage}
        />
      )}
    </Layout>
  )
}

export default BlogParentTemplate
